@font-face {
  font-family: Myriad;
  src: url('./fonts/MyriadPro-Regular.otf');
  letter-spacing: 20em;
}

html,
body {
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  font-variant-numeric: oldstyle-nums;
}

html {
  scroll-snap-type: y proximity;
  transition: all 2s ease-in-out;
}

h2 {
  font-family: 'Myriad', Sans-serif;
  color: #776e62;
}

.title {
  color: #ffffff;
}
.logo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.logo img {
  float: right;
  display: block;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  margin-left: 0.2em;
  color: rgb(240, 240, 240);
  line-height: 1.3em;
  font-weight: 400;
  text-align: center;
  font-size: 3em;
}

.logo:hover {
  opacity: 0.3;
}

a:hover {
  text-decoration-line: none;
}

.vh-100 {
  height: 110vh;
}

.vw-25 {
  width: 25vw;
}

.noMarginTopButtons {
  margin-top: 0;
}
.marginTopButtons {
  margin-top: 5vh;
}
@media screen and (min-width: 2000px) {
  .marginTopButtons {
    margin-top: 2vh;
  }
}

.big-nav-button-wrapper {
  overflow-x: hidden;
  white-space: nowrap;
  width: 300px;
  margin-bottom: 0 !important;
  text-overflow: ellipsis;
}

.p-filter {
  padding: 1.25rem 2.8rem 0.15625rem;
  padding-bottom: 0 !important;
  letter-spacing: 0.02em;
}

.p-filter-remove-black-line {
  padding: 1.3rem 2.8rem 0.15625rem;
  z-index: 1 !important;
  padding-bottom: 50vh;
}

@media screen and (min-width: 1024px) {
  .p-filter-remove-black-line {
    padding: 46px 2.8rem 0.15625rem;
  }
}

.filter-wrapper {
  width: 100%;
}
.nav-icon-progetti {
  position: absolute;
  z-index: 200;
  top: 12px;
  left: 1px;

  display: none;
  width: 1em;
  height: 1em;
  opacity: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
  cursor: pointer;
}
.filter-padding {
  height: 10px;
  margin-top: 15px;
}
.nav-icon-progetti span {
  display: block;
  position: absolute;
  height: 2px;
  width: 55%;
  background: rgb(119, 110, 98);
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-icon-progetti span:nth-child(2) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.nav-icon-progetti.open-progetti {
  display: block;
  position: fixed;
  margin-left: 10px;
  margin-top: 20px;
  opacity: 1;
}

.nav-icon-progetti.open-progetti:hover {
  opacity: 1;
}

.work-wrapper {
  -webkit-overflow-scrolling: touch;
}

.child {
  scroll-snap-align: center;
  -webkit-scroll-snap-align: center;
}
@media screen and (max-width: 1024px) {
  .child {
    scroll-snap-align: none;
    -webkit-scroll-snap-align: none;
  }
}

.childSafari {
  scroll-snap-align: none;
  -webkit-scroll-snap-align: none;
}

.about-centering {
  margin-top: 0px;
}

.child-text {
  padding-top: 20px;
  -webkit-scroll-snap-align: start;
  scroll-snap-align: start;
}

.child-text-padding {
  padding-top: 20px;
}

.w-65 {
  width: 95%;
}

.background-nav-menu {
  background-color: #ffffff !important;
}

.prossimo {
  color: #776e62;
  list-style: none;
  font-size: 20px;
  -webkit-transition: 0.125s ease-in-out;
  -moz-transition: 0.125s ease-in-out;
  -o-transition: 0.125s ease-in-out;
  transition: 0.125s ease-in-out;
}
.prossimo:hover {
  color: black;
}

.filterPadding {
  height: 20px;
}

.hotSpot {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  background-color: rgb(20, 20, 20);
}

.pb-6 {
  height: 100vh;
  margin-bottom: 0 !important;
}

@media screen and (max-width: 600px) {
  .pb-6 {
    height: 100vh;
    margin-bottom: 0 !important;
    padding-top: 20vh;
  }
}

.pt-20 {
  padding-top: 12px;
}

.col-mattia {
  width: 430px !important;
  padding-left: 2.8rem !important;
  padding-right: 15px;
}

.col-about-tab {
  width: 430px !important;
  margin-left: 2.8rem !important;
  margin-right: 15px;
}
.col-about-tab-content {
  width: 430px !important;
  margin-left: 0px !important;
  margin-right: 15px;
}
.hide-mobile {
  display: block;
  position: fixed;
  width: 100%;
  max-width: 430px;
}
.hide-mobile-image {
  display: block;
}
.add-padding-mobile {
  margin-bottom: 0px;
}

.about-sticky-menu-header {
  display: flex;
  flex-direction: column;
  position: relative;
}
.about-menu-tile-margin {
  margin-bottom: 20px;
}
.show-mobile {
  display: none !important;
}
.gray-text-mobile {
  color: #000 !important;
}
.about-menu-button {
  position: absolute;
  color: #5a5a5a;
  left: -25px;
  top: -4px;
  cursor: pointer;
  z-index: 332;
}
.about-content-z-index {
  z-index: 333;
}
.about-wrapper-z-index {
  z-index: 333;
  position: relative;
}
.col-mattia-hack {
  display: none;
}

.noPaddingMatia {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.paddingMatia {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

hr {
  border: 1px solid rgb(158, 146, 130);
  width: 99.5%;
}

.hr-display {
  display: none;
}

.menu-padding {
  height: 0px;
}
.pt-2rem {
  padding-top: 2.1rem;
}

.bw {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.bg-black {
  background-color: #000 !important;
}

.bg-default {
  background-color: rgb(240, 240, 240);
}

.fadePage {
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s ease-in-out;
}

.fadeLogo {
  opacity: 0;
  transition: 1s opacity;
}

.padding-menu {
  height: 2px;
}

.padding-about {
  height: 0px;
}

.about-padding {
  padding-top: -100000px;
}

.fadeOut {
  opacity: 0;
  transition: opacity 2s !important;
}

.fadeIn {
  visibility: visible;
  opacity: 1;
}
.imgEffect2 {
  visibility: hidden;
}
.imgEffect {
  visibility: visible !important;
  animation: fadein 1.5s;
  -moz-animation: fadein 1.5s; /* Firefox */
  -webkit-animation: fadein 1.5s; /* Safari and Chrome */
  -o-animation: fadein 1.5s; /* Opera */
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.img-first-landscape {
  max-height: 75%;
  max-width: 50%;
}

.filter-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2px;
  width: 85vw;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.options-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.img-first-portrait {
  max-height: 75%;
  max-width: 50%;
}

.normalize-bios-text p {
  font-size: 20px;
  font-family: 'Myriad', Sans-serif;
  line-height: 1.5rem;
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;
  color: #776e62;
}

.normalize-bios-text-no-margin p {
  font-size: 20px;
  font-family: 'Myriad', Sans-serif;
  line-height: 1.5rem;
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;
  color: #776e62;
  margin: 0;
}
@media screen and (max-width: 600px) {
  .img-first-landscape {
    max-width: 100%;
    object-fit: cover;
  }
  .img-first-portrait {
    object-fit: cover;
    max-width: 100%;
  }
}
@media screen and (max-width: 500px) and (max-height: 400px) {
  .vh-100 {
    height: 85vh !important;
  }
  .vh-100-main {
    height: 100vh !important;
  }
}

@media screen and (max-width: 500px) and (min-height: 400px) and (max-height: 650px) {
  .vh-100 {
    height: 65vh !important;
  }
  .vh-100-main {
    height: 100vh !important;
  }
}

@media screen and (max-width: 500px) and (max-height: 900px) and (min-height: 650px) {
  .vh-100 {
    height: 75vh !important;
  }
  .vh-100-main {
    height: 100vh !important;
  }
}

@media screen and (max-width: 1200px) and (min-height: 900px) {
  .vh-100 {
    height: 63vh !important;
  }
  .vh-100-main {
    height: 100vh !important;
  }
}

@media screen and (max-width: 1800px) and (min-width: 1200px) and (min-height: 900px) {
  .vh-100 {
    height: 73vh !important;
  }
  .vh-100-main {
    height: 100vh !important;
  }
}

@media screen and (max-height: 600px) and (min-height: 500px) and (min-width: 500px) {
  .vh-100 {
    height: 100vh !important;
  }
}
@media screen and (max-height: 500px) and (min-width: 500px) {
  .vh-100 {
    height: 110vh !important;
  }

  .vh-100-main {
    height: 100vh !important;
  }

  .col-mattia {
    padding-left: 11px;
    padding-right: 11px;
  }

  .col-about-tab {
    margin-left: 11px;
    margin-right: 11px;
  }

  .col-about-tab-content {
    margin-left: 11px;
    margin-right: 11px;
  }
  .line-h {
    line-height: 1.3rem;
  }

  .child-text {
    scroll-snap-align: center;
    -webkit-scroll-snap-align: center;
  }

  .pt-md-0 {
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 767px) {
  .noPaddinTop {
    padding-top: 0px !important;
  }
  .pt-md-2 {
    padding-top: 90px;
  }
}

/* Nexus 5 - iphone 5/SE */
@media screen and (max-width: 360px) and (max-height: 640px) {
  .w-65 {
    width: 95% !important;
  }
}

@media screen and (max-width: 875px) and (max-height: 570px) {
  .w-65 {
    width: 55%;
  }
}

@media screen and (min-width: 500px) {
  .w-65 {
    width: 80%;
  }
  .about-menu-button {
    top: 8px;
  }
}

@media screen and (min-height: 670px) {
  .w-65 {
    width: 82.5%;
  }
}

@media screen and (max-width: 767px) {
  .noPaddinTop {
    padding-top: 0px !important;
  }
}

@media screen and (max-width: 875px) and (min-width: 750px) {
  .col-mattia {
    width: 375px !important;
    padding-left: 95px;
    padding-right: 11px;
    z-index: 1;
  }
  .col-about-tab {
    width: 375px !important;
    margin-left: 95px;
    margin-right: 11px;
    z-index: 1;
  }
  .col-about-tab-content {
    width: 375px !important;
    margin-left: 95px;
    margin-right: 11px;
    z-index: 1;
  }
}
.add-padding-mobile-home {
  padding-left: 15px;
}
@media screen and (max-width: 749px) {
  .col-mattia {
    width: 100% !important;
    padding-left: 11px;
    padding-right: 11px;
    z-index: 1;
  }
  .col-about-tab {
    width: 100% !important;
    margin-left: 11px;
    margin-right: 11px;
    z-index: 1;
  }
  .col-about-tab-content {
    width: 100% !important;
    margin-left: 11px;
    margin-right: 11px;
    z-index: 1;
  }
  .gray-text-mobile {
    color: #776e62 !important;
  }
  .hide-mobile {
    display: none;
  }
  .hide-mobile-image {
    display: none;
  }
  .add-padding-mobile {
    margin-bottom: 40px;
  }

  .add-padding-mobile-home {
    padding-left: 2.8rem;
  }
  .show-mobile {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }
  .about-content-z-index {
    z-index: 197;
    /* margin-top: 20px;*/
    /* min-height: 90vh;*/
  }
  .about-wrapper-z-index {
    z-index: 331;
    position: relative;
  }
  .about-sticky-menu-header {
    position: sticky;
    top: 3rem;
    z-index: 198;
    /*background-color: rgb(240, 240, 240);*/
    padding-top: 0px;
  }
  .about-menu-tile-margin {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 875px) {
  .child-text {
    scroll-snap-align: none;
    -webkit-scroll-snap-align: none;
  }
  .col-mattia-hack {
    display: block !important;
    width: 375px !important;
    padding-left: 11px;
    padding-right: 11px;
    z-index: 1;
  }

  .noPadding {
    padding-top: 0;
  }
  .noMargin {
    margin: 0px 0px 0px !important;
  }
}

@media screen and (min-width: 875px) {
  .col-mattia {
    width: 430px !important;
    padding-left: 45px;
    padding-right: 0px;
  }
  .col-about-tab {
    width: 430px !important;
    margin-left: 45px;
    margin-right: 0px;
  }
  .col-about-tab-content {
    width: 430px !important;
    margin-left: 45px;
    margin-right: 0px;
  }
  .col-mattia-hack {
    display: block !important;
    width: 430px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 1190px) and (max-width: 1290px) {
  .col-mattia-hack {
    display: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .overMenu {
    z-index: 150;
  }
}

@media screen and (max-width: 1190px) {
  .hide-mobile {
    position: relative;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1290px) {
  .col-mattia-hack {
    display: none !important;
  }

  .noMargin {
    margin: 0px 0px 0px !important;
  }
  .hr-display {
    display: block;
  }
}

@media screen and (max-width: 1290px) {
  .noPadding {
    padding-top: 0;
  }

  .hack-2 {
    display: none;
  }

  .pt-2rem {
    padding-top: 0;
  }
}

@media screen and (min-width: 1305px) {
  .col-mattia-hack {
    display: block !important;
    width: 430px !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* redundant rule */
@media screen and (min-width: 1305px) {
  .col-mattia-hack {
    display: none !important;
  }
}

@media screen and (min-width: 1380px) {
  .line-h {
    line-height: 0px;
  }
}

@media screen and (max-width: 1380px) {
  .line-h {
    line-height: 0.8rem;
  }
}

@media (min-width: 2500px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 2000px;
  }
}

.preview {
  position: fixed;
  max-height: 75%;
  max-width: 50%;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  -webkit-transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -o-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
}

.noPaddingHorizontal {
  padding-left: 0 !important;
  margin-top: -1px;
  padding-right: 0 !important;
}

@media screen and (max-width: 750px) {
  .marginLeftMobile {
    margin-left: calc(2.8rem - 10px) !important;
  }
}

@media screen and (max-width: 750px) {
  .padding-about-page {
    height: 40px;
  }
}

.bottom-filter-filler {
  background-color: #ffffff;
  width: 100%;
}

@media screen and (max-width: 750px) {
  .about-image {
    width: 425px;
    object-fit: cover;
    position: static !important;
    margin-left: 0 !important;
    padding: 0 !important;
  }

  .test {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

/* Why? */
@media screen and (max-width: 875px) {
  .about-image {
    width: 470px;
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
}

.about-image {
  margin-top: 75px;
  margin-left: -14px;
  position: fixed;
}

.about-page-wrapper {
  display: flex;
  flex-direction: row;
}

.about-text-columns-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: -16px;
}

.about-text-cell-wrapper {
  display: flex;
  flex-direction: column;
}

.about-text-columns-wrapper .w-md-100 {
  display: flex;
  flex-direction: inherit;
}

.button-after-effect {
  padding: 10px;
  border-radius: 5px;

  /* HOVER OFF */
  -webkit-transition: padding 2s;
}

.button-after-effect:hover {
  padding: 20px;
  border-radius: 15px;

  /* HOVER ON */
  -webkit-transition: border-radius 2s;
}

.space-bottom {
  height: 100% !important;
  width: 100%;
}

.filter-element-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.95rem 0.15625rem;
}

.align-end {
  margin-bottom: 0.2vh !important;
}

.fix-scrollbar {
  position: relative;
  z-index: 1 !important;
  overflow-y: scroll;
  height: 100vh;
}

.sticky-navbar {
  position: fixed;
  transition: 500ms;
  z-index: 199 !important;
}

.sticky-navbar-hidden {
  transition: 500ms;
  background-color: transparent !important;
  margin-top: -50px;
  z-index: 200 !important;
  height: 0 !important;
  margin-bottom: -5vh;
}

.space-top {
  width: 100%;
  transition: 500ms;
  height: 40px;
}

@keyframes filtermenuanim {
  0% {
    width: 100%;
    overflow: auto;
    height: 1vh;
  }
  10% {
    width: 100%;
    overflow: auto;
    height: 3vh;
  }
  20% {
    width: 100%;
    overflow: auto;
    height: 5vh;
  }
  30% {
    width: 100%;
    overflow: auto;
    height: 7vh;
  }
  40% {
    width: 100%;
    overflow: auto;
    height: 9vh;
  }
  50% {
    width: 100%;
    overflow: auto;
    height: 11vh;
  }
  60% {
    width: 100%;
    overflow: auto;
    height: 13vh;
  }
  70% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  80% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  90% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  100% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
}

@-moz-keyframes filtermenuanim {
  /* Firefox */
  0% {
    width: 100%;
    overflow: auto;
    height: 1vh;
  }
  10% {
    width: 100%;
    overflow: auto;
    height: 3vh;
  }
  20% {
    width: 100%;
    overflow: auto;
    height: 5vh;
  }
  30% {
    width: 100%;
    overflow: auto;
    height: 7vh;
  }
  40% {
    width: 100%;
    overflow: auto;
    height: 9vh;
  }
  50% {
    width: 100%;
    overflow: auto;
    height: 11vh;
  }
  60% {
    width: 100%;
    overflow: auto;
    height: 13vh;
  }
  70% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  80% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  90% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  100% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
}
@-webkit-keyframes filtermenuanim {
  /* Safari and Chrome */
  0% {
    width: 100%;
    overflow: auto;
    height: 1vh;
  }
  10% {
    width: 100%;
    overflow: auto;
    height: 3vh;
  }
  20% {
    width: 100%;
    overflow: auto;
    height: 5vh;
  }
  30% {
    width: 100%;
    overflow: auto;
    height: 7vh;
  }
  40% {
    width: 100%;
    overflow: auto;
    height: 9vh;
  }
  50% {
    width: 100%;
    overflow: auto;
    height: 11vh;
  }
  60% {
    width: 100%;
    overflow: auto;
    height: 13vh;
  }
  70% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  80% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  90% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  100% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
}
@-o-keyframes filtermenuanim {
  /* Opera */
  0% {
    width: 100%;
    overflow: auto;
    height: 1vh;
  }
  10% {
    width: 100%;
    overflow: auto;
    height: 3vh;
  }
  20% {
    width: 100%;
    overflow: auto;
    height: 5vh;
  }
  30% {
    width: 100%;
    overflow: auto;
    height: 7vh;
  }
  40% {
    width: 100%;
    overflow: auto;
    height: 9vh;
  }
  50% {
    width: 100%;
    overflow: auto;
    height: 11vh;
  }
  60% {
    width: 100%;
    overflow: auto;
    height: 13vh;
  }
  70% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  80% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  90% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  100% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
}
@media screen and (min-width: 2000px) {
  @keyframes filtermenuanim {
    0% {
      width: 100%;
      overflow: auto;
      height: 1vh;
    }
    10% {
      width: 100%;
      overflow: auto;
      height: 2vh;
    }
    20% {
      width: 100%;
      overflow: auto;
      height: 3vh;
    }
    30% {
      width: 100%;
      overflow: auto;
      height: 4vh;
    }
    40% {
      width: 100%;
      overflow: auto;
      height: 5vh;
    }
    50% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    60% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    70% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    80% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    90% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    100% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
  }
  @-webkit-keyframes filtermenuanim {
    0% {
      width: 100%;
      overflow: auto;
      height: 1vh;
    }
    10% {
      width: 100%;
      overflow: auto;
      height: 2vh;
    }
    20% {
      width: 100%;
      overflow: auto;
      height: 3vh;
    }
    30% {
      width: 100%;
      overflow: auto;
      height: 4vh;
    }
    40% {
      width: 100%;
      overflow: auto;
      height: 5vh;
    }
    50% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    60% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    70% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    80% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    90% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    100% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
  }
  @-moz-keyframes filtermenuanim {
    /* Opera */
    0% {
      width: 100%;
      overflow: auto;
      height: 1vh;
    }
    10% {
      width: 100%;
      overflow: auto;
      height: 3vh;
    }
    20% {
      width: 100%;
      overflow: auto;
      height: 5vh;
    }
    30% {
      width: 100%;
      overflow: auto;
      height: 7vh;
    }
    40% {
      width: 100%;
      overflow: auto;
      height: 9vh;
    }
    50% {
      width: 100%;
      overflow: auto;
      height: 11vh;
    }
    60% {
      width: 100%;
      overflow: auto;
      height: 13vh;
    }
    70% {
      width: 100%;
      overflow: auto;
      height: 15vh;
    }
    80% {
      width: 100%;
      overflow: auto;
      height: 16vh;
    }
    90% {
      width: 100%;
      overflow: auto;
      height: 16vh;
    }
    100% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
  }
}

@keyframes filtermenuanimClose {
  0% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  10% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  20% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  30% {
    width: 100%;
    overflow: auto;
    height: 13vh;
  }
  40% {
    width: 100%;
    overflow: auto;
    height: 11vh;
  }
  50% {
    width: 100%;
    overflow: auto;
    height: 9vh;
  }
  60% {
    width: 100%;
    overflow: auto;
    height: 7vh;
  }
  70% {
    width: 100%;
    overflow: auto;
    height: 5vh;
  }
  80% {
    width: 100%;
    overflow: auto;
    height: 3vh;
  }
  90% {
    width: 100%;
    overflow: auto;
    height: 1vh;
  }
  100% {
    width: 100%;
    overflow: auto;
    height: 0vh;
  }
}
@-moz-keyframes filtermenuanimClose {
  /* Firefox */
  0% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  10% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  20% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  30% {
    width: 100%;
    overflow: auto;
    height: 13vh;
  }
  40% {
    width: 100%;
    overflow: auto;
    height: 11vh;
  }
  50% {
    width: 100%;
    overflow: auto;
    height: 9vh;
  }
  60% {
    width: 100%;
    overflow: auto;
    height: 7vh;
  }
  70% {
    width: 100%;
    overflow: auto;
    height: 5vh;
  }
  80% {
    width: 100%;
    overflow: auto;
    height: 3vh;
  }
  90% {
    width: 100%;
    overflow: auto;
    height: 1vh;
  }
  100% {
    width: 100%;
    overflow: auto;
    height: 0vh;
  }
}
@-webkit-keyframes filtermenuanimClose {
  /* Safari and Chrome */
  0% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  10% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  20% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  30% {
    width: 100%;
    overflow: auto;
    height: 13vh;
  }
  40% {
    width: 100%;
    overflow: auto;
    height: 11vh;
  }
  50% {
    width: 100%;
    overflow: auto;
    height: 9vh;
  }
  60% {
    width: 100%;
    overflow: auto;
    height: 7vh;
  }
  70% {
    width: 100%;
    overflow: auto;
    height: 5vh;
  }
  80% {
    width: 100%;
    overflow: auto;
    height: 3vh;
  }
  90% {
    width: 100%;
    overflow: auto;
    height: 1vh;
  }
  100% {
    width: 100%;
    overflow: auto;
    height: 0vh;
  }
}
@-o-keyframes filtermenuanimClose {
  /* Opera */
  0% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  10% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  20% {
    width: 100%;
    overflow: auto;
    height: auto;
  }
  30% {
    width: 100%;
    overflow: auto;
    height: 13vh;
  }
  40% {
    width: 100%;
    overflow: auto;
    height: 11vh;
  }
  50% {
    width: 100%;
    overflow: auto;
    height: 9vh;
  }
  60% {
    width: 100%;
    overflow: auto;
    height: 7vh;
  }
  70% {
    width: 100%;
    overflow: auto;
    height: 5vh;
  }
  80% {
    width: 100%;
    overflow: auto;
    height: 3vh;
  }
  90% {
    width: 100%;
    overflow: auto;
    height: 1vh;
  }
  100% {
    width: 100%;
    overflow: auto;
    height: 0vh;
  }
}

@media screen and (min-width: 2000px) {
  @keyframes filtermenuanimClose {
    0% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    10% {
      width: 100%;
      overflow: auto;
      height: 5vh;
    }
    20% {
      width: 100%;
      overflow: auto;
      height: 4vh;
    }
    30% {
      width: 100%;
      overflow: auto;
      height: 3vh;
    }
    40% {
      width: 100%;
      overflow: auto;
      height: 2vh;
    }
    50% {
      width: 100%;
      overflow: auto;
      height: 1vh;
    }
    60% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    70% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    80% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    90% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    100% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
  }
  @-moz-keyframes filtermenuanimClose {
    /* Firefox */
    0% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    10% {
      width: 100%;
      overflow: auto;
      height: 5vh;
    }
    20% {
      width: 100%;
      overflow: auto;
      height: 4vh;
    }
    30% {
      width: 100%;
      overflow: auto;
      height: 3vh;
    }
    40% {
      width: 100%;
      overflow: auto;
      height: 2vh;
    }
    50% {
      width: 100%;
      overflow: auto;
      height: 1vh;
    }
    60% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    70% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    80% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    90% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    100% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
  }
  @-webkit-keyframes filtermenuanimClose {
    /* Safari and Chrome */
    0% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    10% {
      width: 100%;
      overflow: auto;
      height: 5vh;
    }
    20% {
      width: 100%;
      overflow: auto;
      height: 4vh;
    }
    30% {
      width: 100%;
      overflow: auto;
      height: 3vh;
    }
    40% {
      width: 100%;
      overflow: auto;
      height: 2vh;
    }
    50% {
      width: 100%;
      overflow: auto;
      height: 1vh;
    }
    60% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    70% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    80% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    90% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    100% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
  }
  @-o-keyframes filtermenuanimClose {
    /* Opera */
    0% {
      width: 100%;
      overflow: auto;
      height: auto;
    }
    10% {
      width: 100%;
      overflow: auto;
      height: 5vh;
    }
    20% {
      width: 100%;
      overflow: auto;
      height: 4vh;
    }
    30% {
      width: 100%;
      overflow: auto;
      height: 3vh;
    }
    40% {
      width: 100%;
      overflow: auto;
      height: 2vh;
    }
    50% {
      width: 100%;
      overflow: auto;
      height: 1vh;
    }
    60% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    70% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    80% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    90% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
    100% {
      width: 100%;
      overflow: auto;
      height: 0vh;
    }
  }
}

.filtermenuanimation {
  animation-name: filtermenuanim;
  animation-duration: 0.25s;
  width: 100%;
  overflow: auto;
  height: auto;
}
.filtermenuanimationClose {
  animation-name: filtermenuanimClose;
  animation-duration: 0.25s;
  width: 100%;
  overflow: auto;
  height: 0;
}
.filtermenuClose {
  width: 100%;
  overflow: auto;
  height: 0;
}
