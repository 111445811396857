p a:nth-child(n){
    color: #776e67;
    border-bottom: 2px solid #776e67;
}

p a:nth-child(n):hover{
    color: black !important;
    border-bottom: 2px solid black;
}

em a:nth-child(n){
    color: #776e67;
    border-bottom: 2px solid #776e67;
}

em a:nth-child(n):hover{
    color: black !important;
    border-bottom: 2px solid black;
}

i a:nth-child(n){
    color: #776e67;
    border-bottom: 2px solid #776e67;
}

i a:nth-child(n):hover{
    color: black !important;
    border-bottom: 2px solid black;
}


b a:nth-child(n){
    color: #776e67;
    border-bottom: 2px solid #776e67;
}

b a:nth-child(n):hover{
    color: black !important;
    border-bottom: 2px solid black;
}

mark a:nth-child(n){
    color: #776e67;
    border-bottom: 2px solid #776e67;
}

mark a:nth-child(n):hover{
    color: black !important;
    border-bottom: 2px solid black;
}

strong a:nth-child(n){
    color: #776e67;
    border-bottom: 2px solid #776e67;
}

strong a:nth-child(n):hover{
    color: black !important;
    border-bottom: 2px solid black;
}


